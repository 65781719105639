body {
  margin: 0;
  font-family: Source Sans Pro", "sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.jodit-toolbar-button button {
  margin-bottom: 0px;
}

.mui-data-table tbody tr:last-child {
  background-color: #f0f0f0;
  /* Cambiar el color de fondo */
  font-weight: bold;
  /* Cambiar la fuente a negrita */
}